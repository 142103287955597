<template>
  <div class="b2c-page-bg">  
    <div>
      <div class="fs-sp">
        <div class="w-100 b2c-bg">
          <div class="w-100 b2c-bg-top">
            <div class="b2c-top-left"></div>
            <div class="b2c-top-center">Vietlott</div>
            <div class="b2c-top-right"></div>
          </div>
        </div>
        <div class="w-100">
        </div>
      </div>
      <div class=" fs-pc">
        <div class="b2c-pc-header">
          <h2>ĐIỀU KHOẢN VPBANK</h2>
          <div class="nav"></div>
        </div>
      </div>
    </div>
    <b-modal body-class="p-2 body__modal-vietlott" header-class="header__modal-vietlott" hide-footer id="modal-vietlott" title="Thông báo!">
      <div class="row m-0">
          <div class="col-12 p-0">
            <h5 class="modal-title d-flex justify-content-center">
              <img src="@/assets/images/logo/logo.svg" class="left-logo" alt="icon-notify">
              <div class="break-line__container">
                <div class="ver__break-line"></div>
              </div>
              <img src="@/assets/images/services/VPBank.svg" class="right-logo" alt="icon-notify">
            </h5>
            <div>
              <p class="text vietlott-info">1. Đây là chương trình liên kết Mở thẻ tín dụng giữa VPBank và Itel. </p>
              <p class="text vietlott-info">2. Bạn sẽ được chuyển hướng sang màn hình mở thẻ tín dụng của VPBank. </p>
              <p class="text vietlott-info">3. VPBank (Ngân hàng TMCP Việt Nam Thịnh Vượng) cung cấp các sản phẩm / dịch vụ tài chính - ngân hàng giúp khách hàng và đối tác một cách minh bạch, thuận tiện, hiệu quả. </p>
              <p class="text vietlott-info">4. Lưu ý: Thông tin chi tiết về điều kiện và điều khoản dịch vụ vui lòng xem<b role="button" class="text-primary uppercase" @click="$bvModal.show('modal-agree')"> tại đây</b></p>
              <p class="text vietlott-info">5. Bạn vui lòng điền các thông tin bên dưới và bấm “Mở thẻ ngay” để tiếp tục nhé.</p>
          </div>
          </div>
          <div class="form-body mt-1">
            <validation-observer ref="infoValid">
            <div class="row p-0 main__form-body">
            <div class="col-md-12 mb-1">
                <validation-provider #default="{ errors }" rules="required|min:3|space">
                  <label class="d-block">Họ tên <span class="text-primary">*</span></label>
                  <b-form-input maxlength="35" size="sm1"
                    v-model="info.name"
                    class="form-input"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Vui lòng nhập đầy đủ họ và tên"/>
                  <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đầy đủ họ và tên</small>
                </validation-provider>
              </div>
              <div class="col-md-12 mb-1">
                <validation-provider #default="{ errors }" rules="required|min:10">
                  <label class="d-block">Điện thoại liên lạc <span class="text-primary">*</span></label>
                  <b-form-input size="sm1" type="text"
                  v-model="info.phoneNumber"
                    class="form-input"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                    maxlength="10"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Số điện thoại"/>
                  <small class="fs-error-txt" v-if="errors.length > 0">Số điện thoại</small>
                </validation-provider>
              </div>
              <div class="col-md-12 mb-1">
                <validation-provider #default="{ errors }" rules="required|email">
                  <label class="d-block">E-mail</label>
                  <b-form-input size="sm1"
                  v-model="info.email"
                    maxlength="40"
                    class="form-input"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Vui lòng nhập Email của bạn"/>
                  <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập Email của bạn</small>
                </validation-provider>
              </div>
            </div>
          </validation-observer>
          </div>
          <!-- <div class="col-12 text-center pt-2 mt-1 mb-3">
            <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-vietlott'); $router.push({ path: '/itshop/tai-chinh-bao-hiem' });">Đóng</button>
            <a class="fs-but1" href="https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb">MỞ THẺ NGAY</a>
          </div> -->
          <div class="footer-modal">
            <!-- <a class="fs-but1 open-card-btn" href="https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb">Mở thẻ ngay</a> -->
            <a class="fs-but1 open-card-btn" href="#" @click="onSubmit">Mở thẻ ngay</a>
          </div>
        </div>
    </b-modal>
    <b-modal body-class="p-2" modal-class="mt-4" hide-header hide-footer id="modal-agree" title="Thông báo!">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title mb-2 border-bottom">Điều Khoản VPBank</h5>
          <p class="text vietlott-info">
            <ul>
              <li>1. Ngân hàng TMCP Việt Nam Thịnh Vượng VPBank là đơn vị có tư cách pháp nhân độc lập, không phải công ty con, chi nhánh hay đơn vị trực thuộc của Công ty CP Viễn thông di động Đông Dương Telecom (ITEL).</li>
              <li>2. Chương trình về mở Thẻ tín dụng là sản phẩm dịch vụ của VPBank, do VPBank độc lập phát triển và cung cấp cho khách hàng, hoàn toàn không liên quan đến ITEL.</li>
              <li>3. Đối với các vấn đề phát sinh vướng mắc và phát sinh khướu nại (nếu có) của khách hàng. VPBank có trách nhiệm hỗ trợ và giải quyết.</li>
              <li>4. Quý khách cần hỗ trợ, vui lòng liên hệ Hotline VPBank: 1900545415</li>
            </ul>
          </p>
        </div>
        <div class="col-12 text-center pt-2 mt-1">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-agree')">Đóng</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import {
  BFormInput
} from "bootstrap-vue";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import { required, min, email, max } from "@validations";

SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

extend("space", (value) => {
  return value.indexOf(" ") > 0;
});

export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      iframeSrc: '',
      phone:'',
      otp:'',
      min,
      max,
      required,
      email,
      info: {
        name: '',
        phoneNumber: '',
        email: ''
      }
    };
  },
  watch: {
    $route(to, from) {
      console.log('a');
      this.$bvModal.show('modal-vietlott');
    },
  },
  created() {

  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.$bvModal.show('modal-vietlott');
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      
      const validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });
      console.log("VALIDATE 0", validate)
      if (!validate) {
        console.log("VALIDATE", validate)
      }
    },
    Img(pic) {
      return images[pic];
    },
    getVietlottView(){
      if (!/^([0-9]{10})$/.test(this.phone)) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      if (!/^([0-9]{6})$/.test(this.otp)) {
        this.$toast.error("Mã OTP gồm 6 chũ số", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      useJwt.get("vietlot/getlink", {params:{phone:this.phone,tran_id:'VL-'+Date.now(),otp:this.otp}})
        .then((response) => {
          console.log(response.data.result)
          this.$bvModal.hide('modal-vietlott');
          
          // window.open(response.data.result);
          window.location.assign(response.data.result)
        }).catch((err) => {
          this.loading(false);
          this.$toast.error("Vui lòng kiểm tra số điện thoại và mã OTP", {
            icon: true,
            closeButton: "button",
          });
        });;
    },
    async sendOTP() {
      if (this.otp.length > 0) {
        this.otp = "";
      }
      if (!/^([0-9]{10})$/.test(this.phone)) {
        this.$toast.error("Vui lòng nhập đúng số thuê bao di động", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.loading(true);
      useJwt.get("otp2/" + this.phone).then((response) => {
          this.$toast.info("Vui lòng kiểm tra tin nhắn để lấy mã OTP", {
            icon: true,
            closeButton: "button",
          });
          this.isDisableOTPText = false;
          this.loading(false);
        }).catch((err) => {
          this.loading(false);
          this.$toast.error(err.response.data.message, {
            icon: true,
            closeButton: "button",
          });
        });
    },
  },
};
</script>
<style>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}
.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 500px;
}
.b2c-pc-header {
  display: flex;
}
.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}
.b2c-pc-header .nav{
  background-image:none !important
}
.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}
.b2c-top-right {
  width: 20%;
}
.modal-title{
  padding-top: 35px;
  font-size: 30px !important;
  color: '#373737' !important;
  font-weight: 700;
  text-align: center;
}
.vietlott-info{
  padding: 0 !important;
  font-family: 'Roboto' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #333333 !important;
}
.fs-but1 {
	padding:0.8rem 2.55rem;
	background-color:#ff2424;
	/*text-transform:uppercase;*/
	font-size:1.8rem;
	font-weight:500;
	color:#fff !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 0);
	cursor:pointer;
	text-align:center;
}
.fs-but1-white {
	padding:0.8rem 4.55rem;
	background-color:#fff;
	font-size:1.8rem;
	font-weight:500;
	color:#ff2424 !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 1);
	cursor:pointer;
	text-align:center;
}

.uppercase {
  text-transform: uppercase;
  font-weight: normal;
}

@media only screen and (min-width: 765px) {
  #modal-vietlott {
    display: flex !important;
    align-items: center !important;
  }
  #modal-vietlott .modal-dialog {
    max-width: none !important;
  }
  #modal-vietlott .modal-content {
    border-radius: 17px !important;
    width: 765px !important;
  }
  #modal-vietlott .left-logo {
    width: 104px;
    height: 57px;
    margin-right: 6px;
  }
  #modal-vietlott .right-logo {
    width: 150px;
    height: 58px;
    margin-left: 10px;
  }
  .break-line__container {
    display: flex;
    height: 58px;
    align-items: center;
  }
  .ver__break-line {
    width: 1px;
    height: 38px;
    background: #D9D9D9;
  }
  .header__modal-vietlott > h5 {
    display: none;
  }
  .header__modal-vietlott button {
    background: none !important;
  }
  .body__modal-vietlott {
    padding: 0 83px 39px !important;
  }
  .body__modal-vietlott .modal-title {
    padding-top: 0 !important;
  }
  .form-body {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main__form-body {
    width: 380px;
  }
  .main__form-body label {
    font-weight: bold;
    margin-bottom: 7px;
  }
  .form-input {
    border: 1px solid rgba(228, 228, 228, 0.6) !important;
    border-radius: 5px !important;
    padding: 15px 20px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
  }
  .form-input::placeholder {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    color: #B4B4B4 !important;
  }
  .footer-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .open-card-btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 11px;
    text-align: center;
    color: #FFFFFF;
    background: #ED1F24;
    border-radius: 26.8042px;
    width: 324px !important;
    height: 48px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 765px) {
  #modal-vietlott {
    display: flex !important;
    align-items: center !important;
    padding: 0 15px !important;
  }
  #modal-vietlott .modal-dialog {
    max-width: none !important;
    width: 100%;
    margin: 0 !important;
  }
  #modal-vietlott .modal-content {
    border-radius: 8px !important;
    width: 100% !important;
  }
  #modal-vietlott .left-logo {
    width: 104px;
    height: 57px;
    margin-right: 6px;
  }
  #modal-vietlott .right-logo {
    width: 150px;
    height: 58px;
    margin-left: 10px;
  }
  .break-line__container {
    display: flex;
    height: 58px;
    align-items: center;
  }
  .ver__break-line {
    width: 1px;
    height: 38px;
    background: #D9D9D9;
  }
  .header__modal-vietlott > h5 {
    display: none;
  }
  .header__modal-vietlott button {
    background: none !important;
  }
  .body__modal-vietlott {
    padding: 0 25px 20px 23px !important;
  }
  .body__modal-vietlott .modal-title {
    padding-top: 0 !important;
    margin-bottom: 10px !important;
  }
  .form-body {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .main__form-body {
    width: 100% !important;
  }
  .main__form-body label {
    font-weight: bold;
    margin-bottom: 7px;
  }
  .form-input {
    border: 1px solid rgba(228, 228, 228, 0.6) !important;
    border-radius: 5px !important;
    padding: 15px 20px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
  }
  .form-input::placeholder {
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    display: flex !important;
    align-items: center !important;
    color: #B4B4B4 !important;
  }
  .footer-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .open-card-btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 11px;
    text-align: center;
    color: #FFFFFF;
    background: #ED1F24;
    border-radius: 26.8042px;
    width: 324px !important;
    height: 48px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


</style>